import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";

import styles from "~/tailwind.css?url";
import type {
  ActionFunction,
  LinksFunction,
  LoaderFunctionArgs,
  SerializeFrom,
} from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { ShoppingCart } from "./components/ShoppingCart";
import MainMenu from "./components/MainMenu";
import { ModalProvider, useModal } from "./context/Modal";
import {
  USER_SESSION_KEY,
  commitSession,
  getSession,
  getUser,
} from "./session.server";
import {
  changeItemQuantity,
  getCartOrUseInMemoryCart,
  removeFromCart,
} from "./cart.server";
import type { CartItem } from "../../types/CartItem";
import { INTENTS } from "./utils/intents";
import { getAllCategoriesWithSubCategories } from "./category.server";
import { getBrands } from "./routes/shop/queries";
import Search from "./components/Search";
import { cookieConsent } from "./cookies.server";
import { CookieConsent } from "./components/CookieConsent";
import animations from "~/animations.css?url";
import linkAnimations from "~/linkAnimations.css?url";
import {
  ExternalScripts,
  ExternalScriptsHandle,
} from "remix-utils/external-scripts";
import { useGA } from "./hooks/useGA";
import { addProfileToList, createProfile, findProfile } from "./klaviyo.server";
import { NOLA_CLUB_ID } from "./utils/constants";

type LoaderData = SerializeFrom<typeof loader>;

export let handle: ExternalScriptsHandle<LoaderData> = {
  scripts(args) {
    const gaTrackingId = args.data.gaTrackingId;
    return [
      {
        type: "text/javascript",
        src: `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`,
        async: false,
        crossOrigin: "anonymous",
      },
    ];
  },
};

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: styles },
  { rel: "stylesheet", href: animations },
  { rel: "stylesheet", href: linkAnimations },
];

export async function loader({ request, context }: LoaderFunctionArgs) {
  const cookieHeader = request.headers.get("Cookie");
  const consetCookie = await cookieConsent.parse(cookieHeader);
  const user = await getUser(request);
  const cart = await getCartOrUseInMemoryCart(
    user ? user.id : "",
    context.cloudflare.env.DB,
  );
  const session = await getSession(request);
  session.set(USER_SESSION_KEY, user);
  const categories = await getAllCategoriesWithSubCategories(
    context.cloudflare.env.CATEGORIES as KVNamespace,
  );
  const brands = await getBrands(context.cloudflare.env.BRANDS as KVNamespace);
  return json(
    {
      categories,
      user,
      cart,
      brands,
      gaTrackingId: context.cloudflare.env.GA_TRACKING_ID,
      showCookieConsent: consetCookie?.cookiesAccepted === undefined,
    },
    { headers: { "set-cookie": await commitSession(session) } },
  );
}

//TODO: for now this implementation is here, but once I move the cart to a nested route,
// this should be moved to the cart route
//This is also handling the newsletter signup
export const action: ActionFunction = async ({ request, context }) => {
  const formData = await request.formData();
  const user = await getUser(request);
  const itemStr = String(formData.get("item"));
  const intent = String(formData.get("intent"));

  if (intent === INTENTS.EMAIL_SIGNUP) {
    const email = String(formData.get("email"));
    const name = String(formData.get("name"));
    const session = await getSession(request);
    session.set(USER_SESSION_KEY, {
      ...user,
      email,
      name,
    });
    let profileId = await findProfile(context, email);
    if (!profileId) {
      profileId = await createProfile(context, email, name);
    }
    if (profileId) {
      await addProfileToList(context, profileId, NOLA_CLUB_ID);
    }

    return json(
      { success: true },
      {
        headers: { "set-cookie": await commitSession(session) },
      },
    );
  }

  if (itemStr) {
    const item: CartItem = JSON.parse(itemStr);
    if (intent === INTENTS.REMOVE_FROM_SHOPPING_CART) {
      await removeFromCart(
        user.id,
        item,
        context.cloudflare.env.DB as D1Database,
      );
      return {
        success: true,
      };
    } else if (intent === INTENTS.INCREASE_QUANTITY) {
      await changeItemQuantity(
        user.id,
        item,
        1,
        context.cloudflare.env.DB as D1Database,
      );
      return {
        success: true,
      };
    } else if (intent === INTENTS.DECREASE_QUANTITY) {
      await changeItemQuantity(
        user.id,
        item,
        -1,
        context.cloudflare.env.DB as D1Database,
      );
      return {
        success: true,
      };
    }
  } else {
    throw new Response("Bad Request", { status: 400 });
  }

  return {
    success: false,
  };
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center">
      <div>Æææ, eitthvað fór úrskeiðis 😢</div>
      <a href="https://nola.is">Ýttu hérna til að halda áfram að versla</a>
    </div>
  );
};

export default function App() {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {/* <meta name="theme-color" content="#FF3636" /> */}
        <Meta />
        <Links />
      </head>
      <ModalProvider>
        <InnerApp />
      </ModalProvider>
    </html>
  );
}

const InnerApp = () => {
  const { gaTrackingId } = useLoaderData<typeof loader>();
  useGA(gaTrackingId);
  const { showCookieConsent } = useLoaderData<{ showCookieConsent: boolean }>();
  const { shoppingCartVisible, mainMenuVisible, filterVisible, searchVisible } =
    useModal();
  return (
    <body
      className={
        shoppingCartVisible !== "hidden" ||
        mainMenuVisible ||
        filterVisible ||
        searchVisible
          ? "overflow-hidden"
          : ""
      }
    >
      <Header />
      <Search />
      <ShoppingCart />
      <MainMenu />
      <Outlet />
      <Footer />
      {showCookieConsent && <CookieConsent />}
      <ScrollRestoration />
      <ExternalScripts />
      <script
        async
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
        }}
      />
      <Scripts />
    </body>
  );
};
